import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import bitcoin from '../../assets/bitcoin.png';
import earth from '../../assets/earth.png';
import earth2 from '../../assets/earth2.png';
import rocket from '../../assets/rocket.png';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
const HomeHeader = () => {
  const navigate = useNavigate();
  return (
    <div className='home-header'>
      <Swiper
        grabCursor={true}
        effect={'creative'}
        loop={true}
        speed={500}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['-200%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[Autoplay]}
        className='mySwiper4'
      >
        <SwiperSlide className='slide-one'>
          <div className='info'>
            <h1>
              THE <span>MOST SECURE</span> CRYPTO CURRENCY
            </h1>
            <p>
              Bitstradeplatform helps over 2 million customers achieve their
              financial goals by helping them trade and invest with ease
            </p>
            <div className='btns'>
              <button className='gold' onClick={() => navigate(`sign-in`)}>
                Login
              </button>
              <button onClick={() => navigate(`sign-up`)}>Join Us</button>
            </div>
          </div>
          <div className='img'>
            <img src={earth} alt='earth' className='earth' />
            <img src={bitcoin} alt='bitcoin' className='bitcoin' />
          </div>
        </SwiperSlide>
        <SwiperSlide className='slide-two'>
          <div className='info'>
            <h1>
              BITCOIN <span>INVEST EASY &</span> SECURE WAY
            </h1>
            <p>
              We are the pioneers of online trading for investors. Today, we are
              a leading financial services firm that specializes in a first
              class experience
            </p>
            <div className='btns'>
              <button className='gold' onClick={() => navigate(`sign-in`)}>
                Login
              </button>
              <button onClick={() => navigate(`sign-up`)}>Join Us</button>
            </div>
          </div>
          <div className='img'>
            <div className='coin-sky'>
              <div className='shower'></div>
            </div>
            <img src={earth2} alt='earth' className='earth' />
            <img src={rocket} alt='rocket' className='rocket' />
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>3</SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default HomeHeader;
