import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/context';
import { BsGiftFill } from 'react-icons/bs';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import TradeNotice from '../components/Trade-Notice/TradeNotice';
import {
  headerInfo,
  portfolioSteps,
  trustedFeatures,
  HelpCenter,
} from '../data/data';

// IMAGES
import headerImage from '../assets/foreground-image-dark.png';
import zeroTradingFee from '../assets/0_btc_fee.webp';
import portfolio from '../assets/portfolio-section.webp';
import trustedTradingPlatform from '../assets/trusted-section.webp';
import CustomersActivities from '../components/Customers-Activities/CustomersActivities';
import HomeHeader from '../components/Home-Header/HomeHeader';

export default function Home() {
  const path = useLocation().pathname;
  const [showActivities, setShowActivities] = useState(false);
  const {
    wakeServer,
    resetPage,
    fetchPopularCryptos,
    cryptos,
    showTradeNotice,
  } = useGlobalContext();

  useEffect(() => {
    wakeServer();
    resetPage(`Bitcoin and Crypto Trading Platform`);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchPopularCryptos();
    // eslint-disable-next-line
  }, []);

  // SHOW CUSTOMERS ACTIVITIES
  // useEffect(() => {
  //   if (!path.startsWith(`/dashboard`)) {
  //     const showComponentTimer = setInterval(() => {
  //       setShowActivities(true);
  //       setTimeout(() => setShowActivities(false), 3000);
  //     }, 5000);

  //     return () => {
  //       clearInterval(showComponentTimer);
  //     };
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <main className='home'>
        <HomeHeader />
        <Header />
        <PopularCryptos cryptos={cryptos} />
        <TradeCryptoForFree />
        <CryptoPortfolio />
        <TrustedTradingPlatform />
        <NeedHelp />
        <StartEarning />
        {showActivities && !path.includes(`/dashboard`) && (
          <CustomersActivities />
        )}
        {showTradeNotice && <TradeNotice />}
      </main>
    </>
  );
}

const Header = () => {
  return (
    <header>
      <div className='top'>
        <div className='left'>
          <h1>
            On Bitstradeplatform, you can purchase, trade, and securely hold
            more than 350 cryptocurrencies.
          </h1>
          <div className='trade-for-free'>
            <BsGiftFill className='icon' />
            <p>
              Execute Bitcoin trades without incurring any fees on
              Bitstradeplatform.
            </p>
          </div>
          <Link to='sign-up' className='link'>
            <button className='gold border'>
              <span>
                <FaUserAlt className='icon' />
              </span>{' '}
              Sign up with Email
            </button>
          </Link>
          <div className='active-user'>
            <span></span>
            <p>or</p>
            <span></span>
          </div>
          <Link to='sign-in' className='link'>
            <button className='grey'>
              <span>
                <FaUserAlt className='icon' />
              </span>{' '}
              Login
            </button>
          </Link>
        </div>
        <div className='right'>
          <img src={headerImage} alt='header' />
        </div>
      </div>
      <div className='bottom'>
        {headerInfo.map((info, index) => {
          return (
            <div className='info' key={index}>
              <h3>{info.num}</h3>
              <p>{info.brief}</p>
            </div>
          );
        })}
      </div>
    </header>
  );
};

const PopularCryptos = ({ cryptos }) => {
  return (
    <section className='popular-cryptos'>
      <h2>Popular cryptocurrencies</h2>

      <div className='table'>
        <div className='table-header'>
          <h4>Name</h4>
          <h4>Last Price</h4>
          <h4>24h Change</h4>
          <h4>Market Cap</h4>
        </div>
        <div className='table-content'>
          {cryptos.map((crypto, index) => {
            const {
              name,
              symbol,
              market_cap_change_percentage_24h,
              market_cap,
              current_price,
              image,
            } = crypto;

            return (
              <div className='crypto' key={index}>
                <div>
                  <span className='img'>
                    <img src={image} alt={name} />
                  </span>
                  <span>{name}</span>
                  <span>{symbol}</span>
                </div>
                <div>${current_price.toLocaleString(`en-US`)}</div>
                <div
                  className={
                    market_cap_change_percentage_24h.toString().includes(`-`)
                      ? `fall`
                      : `rise`
                  }
                >
                  {market_cap_change_percentage_24h}%
                </div>
                <div>${market_cap.toLocaleString(`en-US`)}</div>
              </div>
            );
          })}
        </div>
      </div>
      <h3>Sign up now to build your own portfolio for free!</h3>
      <Link to='/sign-up'>
        <button className='gold'>Get Started</button>
      </Link>
    </section>
  );
};

const TradeCryptoForFree = () => {
  return (
    <section className='trade-crypto-for-free'>
      <div className='img'>
        <img src={zeroTradingFee} alt='zero trading fees' />
      </div>
      <div className='text'>
        <h2>Trade Bitcoin for Free</h2>
        <p>0 trading fee on selected Bitcoin (BTC) spot trading pairs now</p>
        <p>Lowest transactions fees &#60;0.10%</p>
        <Link to='sign-in'>
          <button className='gold'>Trade BTC for 0 fee</button>
        </Link>
      </div>
    </section>
  );
};

const CryptoPortfolio = () => {
  return (
    <section className='crypto-portfolio'>
      <div className='info'>
        <h2>Build your crypto portfolio</h2>
        <p>Start your first trade with these easy steps.</p>
        <div className='portfolio-img'>
          <img src={portfolio} alt='crypto portfolio' />
        </div>
        {portfolioSteps.map((feature, index) => {
          const { step, info, img } = feature;
          return (
            <div className='step' key={index}>
              <img src={img} alt={step} />
              <div className='info'>
                <h3>{step}</h3>
                <p>{info}</p>
              </div>
            </div>
          );
        })}
        <Link to='sign-up'>
          <button className='gold'>Get Started</button>
        </Link>
      </div>
      <div className='img'>
        <img src={portfolio} alt='crypto portfolio' />
      </div>
    </section>
  );
};

const TrustedTradingPlatform = () => {
  return (
    <section className='trusted-trading-platform'>
      <div className='info'>
        <h2>Your trusted Trading Platform</h2>
        <p>
          Here at Bitstradeplatform, we are committed to user protection with
          strict protocols and industry-leading technical measures.
        </p>
        <div className='portfolio-img'>
          <img src={trustedTradingPlatform} alt='crypto portfolio' />
        </div>
        {trustedFeatures.map((feature, index) => {
          const { step, info, img } = feature;
          return (
            <div className='step' key={index}>
              <img src={img} alt={step} />
              <div className='info'>
                <h3>{step}</h3>
                <p>{info}</p>
              </div>
            </div>
          );
        })}
        <Link to='sign-up'>
          <button className='gold'>Start Now</button>
        </Link>
      </div>
      <div className='img'>
        <img src={trustedTradingPlatform} alt='crypto portfolio' />
      </div>
    </section>
  );
};

const NeedHelp = () => {
  return (
    <section className='need-help'>
      <h2>Need help?</h2>
      <div className='options'>
        {HelpCenter.map((help, index) => {
          return (
            <div className='help' key={index}>
              <div className='img'>
                <img src={help.logo} alt={help.name} />
              </div>
              <div className='info'>
                <h4>{help.name}</h4>
                <p>{help.info}</p>
                <Link to={help.link} className='prompt'>
                  {help.prompt}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const StartEarning = () => {
  return (
    <section className='start-earning'>
      <h2>Start earning today</h2>
      <div className='btn'>
        <Link to='sign-up' className='link'>
          <button className='gold'>Sign Up Now</button>
        </Link>
      </div>
    </section>
  );
};
