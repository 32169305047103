import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  return localStorage.getItem(`userDetails`) ? (
    children
  ) : (
    <Navigate to='/sign-in' />
  );
};

export default PrivateRoute;
