import React from 'react';
import { useGlobalContext } from '../../context/context';

const TradeNotice = () => {
  const { setShowTradeNotice } = useGlobalContext();
  return (
    <div className='trade-notice'>
      <div className='modal'>
        <p>
          Notice: All trades and investmets placed on this platform are 100%
          secured, so traders should be confident as trade success is 100%
          guaranteed.
        </p>

        <div className='close-btn'>
          <h2 onClick={() => setShowTradeNotice(false)}>Close</h2>
        </div>
      </div>
    </div>
  );
};

export default TradeNotice;
