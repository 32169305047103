import React from 'react';
import { BsCurrencyBitcoin } from 'react-icons/bs';

const PageLoader = () => {
  return (
    <div className='page-loader'>
      <div className='container'>
        <div className='bitcoin'>
          <BsCurrencyBitcoin />
        </div>
        <div className='step step-one'></div>
        <div className='step step-two'></div>
        <div className='step step-three'></div>
      </div>
    </div>
  );
};

export default PageLoader;
