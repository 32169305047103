import React, { useContext, useState } from 'react';
import axios from 'axios';
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.Bitstradeplatform.com`;
  const [isNavbar, setIsNavbar] = useState(false);
  const [showTradeNotice, setShowTradeNotice] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [seedPhrase, setSeedPhrase] = useState(``);
  const [userDetails, setUserDetails] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [depositAmount, setDepositAmount] = useState({
    amount: 0,
  });
  const [bankWithdrawalAmount, setBankWithdrawalAmount] = useState({
    amount: 0,
  });
  const [cryptoWithdrawalAmount, setCryptoWithdrawalAmount] = useState({
    amount: 0,
  });
  const [userToken, setUserToken] = useState(``);
  const [loading, setLoading] = useState(false);
  const [cryptos, setCryptos] = useState([]);
  const [updateInfo, setUpdateInfo] = useState(false);

  // FUNCTION TO WAKE SERVER
  const wakeServer = async () => {
    try {
      const response = await fetch(baseURL);
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // FUNCTION TO FETCH UPDATED USER DETAILS
  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/transaction/token-user`, {
        method: `GET`,
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-type': 'application/json',
        },
      });
      const data = await response.json();
      setLoading(false);
      setUserDetails(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // FUCTION TO SEND POST REQUEST FOR WITHDRAWAL

  const withdrawFunds = async (withdrawalAmount, wallet) => {
    try {
      await axios.post(
        `${baseURL}/withdrawal/add`,
        { amount: Number(withdrawalAmount), wallet },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPopularCryptos = async () => {
    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`
      );

      const data = await response.json();
      setCryptos(data.slice(0, 5));
    } catch (error) {
      console.log(error);
    }
  };

  // CLOSE SIDEBAR WHENEVER LINKS ARE CLICKED
  const handleClick = () => {
    setIsNavbar(false);
  };

  // FUNCTION TO HANDLE COPY

  const handleCopy = (target) => {
    const copyText = document.querySelector(`${target}`).textContent;
    navigator.clipboard.writeText(copyText);
    const copyBtn = document.querySelector(`.copy-btn`);
    copyBtn.classList.add(`copied`);
  };

  // FUNCTION TO REVEAL PASSWORD
  const revealPassword = () => {
    const passwordInput = document.querySelector(`#password`);
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  };

  // FUNCTION TO RETURN FOCUS BACK TO THE TOP OF THE PAGE ON PAGE RENDER

  const resetPage = (title) => {
    window.scrollTo({ top: 0, left: 0 });
    document.title = `${title} | Bitstradeplatform`;
  };

  return (
    <AppContext.Provider
      value={{
        baseURL,
        isNavbar,
        showTradeNotice,
        isUser,
        showModal,
        seedPhrase,
        userDetails,
        loginError,
        errorMessage,
        forgotPassword,
        depositAmount,
        cryptoWithdrawalAmount,
        bankWithdrawalAmount,
        userToken,
        loading,
        cryptos,
        setIsNavbar,
        setShowTradeNotice,
        setIsUser,
        setShowModal,
        setSeedPhrase,
        setUserDetails,
        setLoginError,
        setErrorMessage,
        setForgotPassword,
        setDepositAmount,
        setCryptoWithdrawalAmount,
        setBankWithdrawalAmount,
        setUserToken,
        setLoading,
        handleClick,
        wakeServer,
        fetchUser,
        withdrawFunds,
        handleCopy,
        resetPage,
        fetchPopularCryptos,
        revealPassword,
        updateInfo,
        setUpdateInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
